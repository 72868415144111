export const jsonLDConfigs = [
	{
		url: ['/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},

			"itemListElement":
			{
				"@context": "https://schema.org/",
				"@type": "SiteNavigationElement",
				"itemListElement":
					[
						{
							"position": 1,
							"name": "About Us",
							"description": "Third Rock Techkno is a top web and mobile app development company in India & USA. We strive to help your business scale greater heights with our efficient tech solutions.",
							"url": "https://www.thirdrocktechkno.com/about-us"
						},
						{
							"@type": "ListItem",
							"position": 2,
							"name": "Blog",
							"description": "Insights, stories and updates on the latest trends in web development, mobile development, and other leading software development technologies.",
							"item": "https://www.thirdrocktechkno.com/blog"
						},
						{
							"@type": "ListItem",
							"position": 3,
							"name": "Service",
							"description": "Third Rock Techkno has a versatile team providing full-stack development services ranging from mobile app development to web development & voice development.",
							"item": "https://www.thirdrocktechkno.com/services"
						},
						{
							"@type": "ListItem",
							"position": 4,
							"name": "Portfolio",
							"description": "We have delivered a wide range of projects with 100% efficiency. Take a look at our web & mobile development portfolio of projects across various industries.",
							"item": "https://www.thirdrocktechkno.com/portfolio"
						},
						{
							"@type": "ListItem",
							"position": 5,
							"name": "Our Clients",
							"description": "Third Rock Techkno works with startups and enterprises to build high-quality, customer-centric software experiences. We help businesses that strive to put their customers first, while scaling capacity and capability quickly.",
							"item": "https://www.thirdrocktechkno.com/our-clients"
						},
						{
							"@type": "ListItem",
							"position": 6,
							"name": "Solutions",
							"description": "Third rock techkno offers Industry IT Solutions for different industries counting healthcare, fintech and other industrie",
							"item": "https://www.thirdrocktechkno.com/solutions"
						}
					]
			},

		}
		),
	},
	{
		url: ['/about-us'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/portfolio'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/portfolio/music-learning-app/riyaz'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Riyaz',
					item: 'https://www.thirdrocktechkno.com/portfolio/music-learning-app/riyaz'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/carjasoos'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Car Jasoos',
					item: 'https://www.thirdrocktechkno.com/portfolio/carjasoos'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/nins'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'NINS',
					item: 'https://www.thirdrocktechkno.com/portfolio/nins'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/rentalq'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'RentalQ',
					item: 'https://www.thirdrocktechkno.com/portfolio/rentalq'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/nextpond'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Nextpond',
					item: 'https://www.thirdrocktechkno.com/portfolio/nextpond'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/smoovr'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Smoovr',
					item: 'https://www.thirdrocktechkno.com/portfolio/smoovr'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/maxwell'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Maxwell',
					item: 'https://www.thirdrocktechkno.com/portfolio/maxwell'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/xclusive'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Xclusive',
					item: 'https://www.thirdrocktechkno.com/portfolio/xclusive'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/newleadprospect'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'New Lead Prospect',
					item: 'https://www.thirdrocktechkno.com/portfolio/newleadprospect'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/onemilliondream'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'One Million Dream',
					item: 'https://www.thirdrocktechkno.com/portfolio/onemilliondream'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/medicaldomainapplication'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'vaccin à domicile',
					item: 'https://www.thirdrocktechkno.com/portfolio/medicaldomainapplication'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/alexa-skill-and-google-action-for-night-club-and-restaurant'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Imperial Night Club',
					item: 'https://www.thirdrocktechkno.com/portfolio/alexa-skill-and-google-action-for-night-club-and-restaurant'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/alexa-skills-and-google-assistance-for-a-transport-company'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mater Tow',
					item: 'https://www.thirdrocktechkno.com/portfolio/alexa-skills-and-google-assistance-for-a-transport-company'
				}],
			}
		}),
	},
	{
		url: ['/portfolio/alexa-skills-and-Google-assistance-for-a-taxi-company'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Portfolio',
					item: 'https://www.thirdrocktechkno.com/portfolio'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'RadixCab',
					item: 'https://www.thirdrocktechkno.com/portfolio/alexa-skills-and-Google-assistance-for-a-taxi-company'
				}],
			}
		}),
	},
	{
		url: ['/services'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/hire-us'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/hire-us/hire-web-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}],
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}],
			}
		}),
	},
	{
		url: ['/hire-us/hire-voice-developers/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Voice Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-voice-developers/'
				}],
			}
		}),
	},
	{
		url: ['/industries-we-serve'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [{
				'@type': 'ListItem',
				position: 1,
				name: 'Home',
				item: 'https://www.thirdrocktechkno.com/'
			}, {
				'@type': 'ListItem',
				position: 2,
				name: 'Industries',
				item: 'https://www.thirdrocktechkno.com/industries-we-serve'
			}],
		}),
	},
	{
		url: ['/industries-we-serve/media-and-entertainment/'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [
				{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/',
				},
				{
					'@type': 'ListItem',
					position: 2,
					name: 'Industries',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve',
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'Media and entertainment',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve/media-and-entertainment/',
				},
			],
		}),
	},
	{
		url: ['/industries-we-serve/automobiles/'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [
				{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/',
				},
				{
					'@type': 'ListItem',
					position: 2,
					name: 'Industries',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve',
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'Automobiles',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve/automobiles/',
				},
			],
		}),
	},
	{
		url: ['/industries-we-serve/education/'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [
				{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/',
				},
				{
					'@type': 'ListItem',
					position: 2,
					name: 'Industries',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve',
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'Education',
					item: 'https://www.thirdrocktechkno.com/industries-we-serve/education/',
				},
			],
		}),
	},
	{
		url: ['/blog'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Person": {
				"@context": "https://schema.org/",
				"@type": "Person",
				"name": "Krunal Shah",
				"url": "https://www.thirdrocktechkno.com/author/krunal-shah/",
				"image": "https://s3-ap-south-1.amazonaws.com/trt-blog-ghost/2022/12/krunal-profile-photo_11_11zon_11_11zon.webp",
				"sameAs": [
					"https://www.linkedin.com/in/krunalhshah",
					"https://twitter.com/krunal86"
				],
				"jobTitle": "CTO",
				"worksFor": {
					"@type": "Organization",
					"name": "Third Rock Techkno"
				},
			},
			"Article": {
				"@context": "https://schema.org",
				"@type": "Article",
				"mainEntityOfPage": {
					"@type": "WebPage",
					"@id": "https://www.thirdrocktechkno.com/blog"
				},
				"headline": "Discover Blog",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"author": {
					"@type": "Person",
					"name": "Krunal Shah",
					"url": "https://www.thirdrocktechkno.com/author/krunal-shah/"
				},
				"publisher": {
					"@type": "Organization",
					"name": "Krunal Shah",
					"logo": {
						"@type": "ImageObject",
						"url": "https://www.thirdrocktechkno.com/images/logo.png"
					}
				},
				"datePublished": "2017-05-06",
				"dateModified": "2023-01-20"
			}

		}),
	},
	{
		url: ['/life-at-trt'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/career'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/privacy-policy'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/faq'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],

			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "What is a ball park cost to create an application?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "It totally depends on the features and complexity of the application. So it may be covered in the range starting from - $5, 000 USD."
					}
				}, {
					"@type": "Question",
					"name": "What would be the payment policy?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "It depends on the model you finalize to with. If it’s a fixed cost model The payment policy would be defined in milestones. If you go with the hourly based model then the payment cycle should be weekly or 15 days."
					}
				}, {
					"@type": "Question",
					"name": "Do you follow an AGILE methodology?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, We follow agile methodology. But in some cases depending on project size and the type of project we also follow the Waterfall method."
					}
				}, {
					"@type": "Question",
					"name": "How can I track the progress of my project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We will give you the credentials of the project management tool we use and our team will also be in touch via emails or Skype calls. Also, we will have a stand-up meeting on a daily or weekly basis."
					}
				}, {
					"@type": "Question",
					"name": "Who will be the owner of the source code?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Once we receive the full payment of the project, you will be the owner of the complete source code."
					}
				}, {
					"@type": "Question",
					"name": "What technologies do you guys work on?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Our technology stack - Angularjs, Nodejs, Reactjs, AWS, Swift, Java, Python, Android, iOS, Ionic, React Native, Augmented Reality, Virtual Reality, Wearable apps, Amazon Alexa, Google Actions. (We have also worked on other technologies like Wordpress, Magento and more)."
					}
				}, {
					"@type": "Question",
					"name": "What should be the minimum size of a team, I have to hire?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "There is no restriction on hiring resources.You can hire one or a team of developers based on your requirements."
					}
				}, {
					"@type": "Question",
					"name": "Is it possible for us to hire a developer on a weekly basis?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes - It's totally upon you, you can go for weekly as well as monthly basis."
					}
				},
				{
					"@type": "Question",
					"name": "Do you have your own development center, or do you work on subcontract?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We have our development office at Ahmedabad, India. We strictly oppose the subcontracting process."
					}
				},
				{
					"@type": "Question",
					"name": "Do you have in house design team?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, we have in -house designing team along with the QA team and the developers who follow the motto - Eat - Sleep - Code."
					}
				},
				{
					"@type": "Question",
					"name": "Would you help us in providing the consultation before starting the project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, for sure. We can also help you in giving ideas and suggestions before we start working on a project with a very minimal charge."
					}
				},
				{
					"@type": "Question",
					"name": "What is your team strength?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We are a team of 50 + TechXperts / Techies."
					}
				},
				{
					"@type": "Question",
					"name": "What is your experience in terms of project completion?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We have successfully completed over 50 + smaller as well as bigger projects with more than 40 + smiling faces of our clients."
					}
				},
				{
					"@type": "Question",
					"name": "How many years you have spent in industry?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Third Rock Techkno has have over 4 + years of experience and our journey started on 1st April 2014 by the 3 Master Minds while having a Cup of coffee together at CCD."
					}
				},
				{
					"@type": "Question",
					"name": "Will you replace my developer if I am not satisfied with the performance?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes. We will definitely replace your developer if we see that there is performance issue on the developer’s end."
					}
				},
				{
					"@type": "Question",
					"name": "How is reporting arranged? How often will I be updated?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Hired developers send the reports on a daily basis via email and slack. If the client wants any other update then that also can be arranged on mutual understanding."
					}
				},
				{
					"@type": "Question",
					"name": "Would you take care of my application once it gets live?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, we provide free support for a limited period once the app goes live. We provide paid support at very nominal rates."
					}
				}
				]
			}
		}),
	},
	{
		url: ['/contact-us'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/services/voice-assistant-applications-development/alexa-skill-development/'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [{
				'@type': 'ListItem',
				position: 1,
				name: 'Home',
				item: 'https://www.thirdrocktechkno.com/'
			}, {
				'@type': 'ListItem',
				position: 2,
				name: 'Services',
				item: 'https://www.thirdrocktechkno.com/services'
			}, {
				'@type': 'ListItem',
				position: 3,
				name: 'Voice Development',
				item: 'https://www.thirdrocktechkno.com/services/voice-assistant-applications-development'
			}, {
				'@type': 'ListItem',
				position: 4,
				name: 'Alexa Voice Assistant',
				item: 'https://www.thirdrocktechkno.com/services/voice-assistant-applications-development/alexa-skill-development/'
			}],
		}),
	},
	{
		url: ['/services/voice-assistant-applications-development/google-actions-development/'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [{
				'@type': 'ListItem',
				position: 1,
				name: 'Home',
				item: 'https://www.thirdrocktechkno.com/'
			}, {
				'@type': 'ListItem',
				position: 2,
				name: 'Services',
				item: 'https://www.thirdrocktechkno.com/services'
			}, {
				'@type': 'ListItem',
				position: 3,
				name: 'Voice Development',
				item: 'https://www.thirdrocktechkno.com/services/voice-assistant-applications-development'
			}, {
				'@type': 'ListItem',
				position: 4,
				name: 'Google Assistant',
				item: 'https://www.thirdrocktechkno.com/services/voice-assistant-applications-development/google-actions-development/'
			}],
		}),
	},
	{
		url: ['/services/web-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Web Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "How much does a web application cost?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The cost of developing a web application varies from one project to another on the basis of the application platform and the kind of features and functionalities you need in the application. Contact us with your project requirements to get a cost estimation for your project."
					}
				}, {
					"@type": "Question",
					"name": "How long does it take to develop a custom web application?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Again, web development for different kinds of applications requires different timeframes. If you need a project to be delivered in a certain period of time, we can make it possible by employing the necessary resources in the development process."
					}
				}, {
					"@type": "Question",
					"name": "Do you provide maintenance and support for web development after the project is completed?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Certainly. We commit free support for a certain period once the project is live. After the free support period ends, you can get complete maintenance and support for your project at very nominal rates."
					}
				}, {
					"@type": "Question",
					"name": "Can I hire dedicated developers for web development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, you can hire dedicated developers for your project. We offer multiple payment models to enable you to choose an approach that suits you the best."
					}
				}, {
					"@type": "Question",
					"name": "Why choose Third Rock Techkno for web app development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "With 5+ years of experience and 50+ satisfied customers, our versatile development teams and consultants with sharp business acumen are experts in building scalable and efficient web apps with maximum ROI."
					}
				},]
			}
		}),
	},
	{
		url: ['/services/web-development/angular-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Web Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Angular Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development/angular-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	// {
	// 	url: ['/services/web-development/nodejs-development/'],
	// 	value: JSON.stringify({
	// 		"@type": "Website",
	// 		"name": "Third Rock Techkno",
	// 		"url": "https://www.thirdrocktechkno.com",
	// 		"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

	// 		"BreadcrumbList":
	// 		{
	// 			'@context': 'https://schema.org/',
	// 			'@type': 'BreadcrumbList',
	// 			itemListElement: [{
	// 				'@type': 'ListItem',
	// 				position: 1,
	// 				name: 'Home',
	// 				item: 'https://www.thirdrocktechkno.com/'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 2,
	// 				name: 'Services',
	// 				item: 'https://www.thirdrocktechkno.com/services'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 3,
	// 				name: 'Web Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 4,
	// 				name: 'Node JS Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development/nodejs-development/'
	// 			}],
	// 		},
	// 		"Organization":
	// 		{
	// 			"@context": "http://www.schema.org",
	// 			"@type": "Organization",
	// 			"name": "Third Rock Techkno",
	// 			"url": "https://www.thirdrocktechkno.com",
	// 			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"image": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
	// 			"sameAs": [
	// 				"https://www.facebook.com/ThirdRockTechkno",
	// 				"https://twitter.com/thirdrocktechno",
	// 				"https://www.linkedin.com/company/third-rock-techkno",
	// 			],
	// 		},
	// 		"LocalBusiness": {
	// 			'@context': 'https://schema.org',
	// 			'@type': 'LocalBusiness',
	// 			name: 'Third Rock Techkno',
	// 			image: 'https://www.thirdrocktechkno.com/images/logo.png',
	// 			'@id': 'https://www.thirdrocktechkno.com/',
	// 			url: 'https://www.thirdrocktechkno.com/',
	// 			priceRange: '$25 - $49',
	// 			"address": {
	// 				"@type": "PostalAddress",
	// 				"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
	// 				"addressLocality": "Ahmedabad",
	// 				"addressRegion": "Gujrat",
	// 				"postalCode": "380009",
	// 				"addressCountry": "India"
	// 			},
	// 			"contactPoint": {
	// 				"@type": "ContactPoint",
	// 				"telephone": "+91 79 48917579"
	// 			},
	// 			"geo": {
	// 				"@type": "GeoCoordinates",
	// 				"latitude": 23.0335,
	// 				"longitude": 72.5557,
	// 			},
	// 			"aggregateRating": {
	// 				"@type": "AggregateRating",
	// 				"ratingValue": "4.9",
	// 				"bestRating": "5",
	// 				"ratingCount": "17"
	// 			}
	// 		},
	// 	}),
	// },
	// {
	// 	url: ['/services/web-development/reactjs-development/'],
	// 	value: JSON.stringify({
	// 		"@type": "Website",
	// 		"name": "Third Rock Techkno",
	// 		"url": "https://www.thirdrocktechkno.com",
	// 		"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

	// 		"BreadcrumbList":
	// 		{
	// 			'@context': 'https://schema.org/',
	// 			'@type': 'BreadcrumbList',
	// 			itemListElement: [{
	// 				'@type': 'ListItem',
	// 				position: 1,
	// 				name: 'Home',
	// 				item: 'https://www.thirdrocktechkno.com/'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 2,
	// 				name: 'Services',
	// 				item: 'https://www.thirdrocktechkno.com/services'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 3,
	// 				name: 'Web Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 4,
	// 				name: 'React JS Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development/reactjs-development/'
	// 			}],
	// 		},
	// 		"Organization":
	// 		{
	// 			"@context": "http://www.schema.org",
	// 			"@type": "Organization",
	// 			"name": "Third Rock Techkno",
	// 			"url": "https://www.thirdrocktechkno.com",
	// 			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"image": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
	// 			"sameAs": [
	// 				"https://www.facebook.com/ThirdRockTechkno",
	// 				"https://twitter.com/thirdrocktechno",
	// 				"https://www.linkedin.com/company/third-rock-techkno",
	// 			],
	// 		},
	// 		"LocalBusiness": {
	// 			'@context': 'https://schema.org',
	// 			'@type': 'LocalBusiness',
	// 			name: 'Third Rock Techkno',
	// 			image: 'https://www.thirdrocktechkno.com/images/logo.png',
	// 			'@id': 'https://www.thirdrocktechkno.com/',
	// 			url: 'https://www.thirdrocktechkno.com/',
	// 			priceRange: '$25 - $49',
	// 			"address": {
	// 				"@type": "PostalAddress",
	// 				"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
	// 				"addressLocality": "Ahmedabad",
	// 				"addressRegion": "Gujrat",
	// 				"postalCode": "380009",
	// 				"addressCountry": "India"
	// 			},
	// 			"contactPoint": {
	// 				"@type": "ContactPoint",
	// 				"telephone": "+91 79 48917579"
	// 			},
	// 			"geo": {
	// 				"@type": "GeoCoordinates",
	// 				"latitude": 23.0335,
	// 				"longitude": 72.5557,
	// 			},
	// 			"aggregateRating": {
	// 				"@type": "AggregateRating",
	// 				"ratingValue": "4.9",
	// 				"bestRating": "5",
	// 				"ratingCount": "17"
	// 			}
	// 		},
	// 	}),
	// },
	// {
	// 	url: ['/services/web-development/vuejs-development/'],
	// 	value: JSON.stringify({
	// 		"@type": "Website",
	// 		"name": "Third Rock Techkno",
	// 		"url": "https://www.thirdrocktechkno.com",
	// 		"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

	// 		"BreadcrumbList":
	// 		{
	// 			'@context': 'https://schema.org/',
	// 			'@type': 'BreadcrumbList',
	// 			itemListElement: [{
	// 				'@type': 'ListItem',
	// 				position: 1,
	// 				name: 'Home',
	// 				item: 'https://www.thirdrocktechkno.com/'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 2,
	// 				name: 'Services',
	// 				item: 'https://www.thirdrocktechkno.com/services'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 3,
	// 				name: 'Web Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development'
	// 			}, {
	// 				'@type': 'ListItem',
	// 				position: 4,
	// 				name: 'Vue JS Development',
	// 				item: 'https://www.thirdrocktechkno.com/services/web-development/vuejs-development/'
	// 			}],
	// 		},
	// 		"Organization":
	// 		{
	// 			"@context": "http://www.schema.org",
	// 			"@type": "Organization",
	// 			"name": "Third Rock Techkno",
	// 			"url": "https://www.thirdrocktechkno.com",
	// 			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"image": "https://www.thirdrocktechkno.com/images/logo.png",
	// 			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
	// 			"sameAs": [
	// 				"https://www.facebook.com/ThirdRockTechkno",
	// 				"https://twitter.com/thirdrocktechno",
	// 				"https://www.linkedin.com/company/third-rock-techkno",
	// 			],
	// 		},
	// 		"LocalBusiness": {
	// 			'@context': 'https://schema.org',
	// 			'@type': 'LocalBusiness',
	// 			name: 'Third Rock Techkno',
	// 			image: 'https://www.thirdrocktechkno.com/images/logo.png',
	// 			'@id': 'https://www.thirdrocktechkno.com/',
	// 			url: 'https://www.thirdrocktechkno.com/',
	// 			priceRange: '$25 - $49',
	// 			"address": {
	// 				"@type": "PostalAddress",
	// 				"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
	// 				"addressLocality": "Ahmedabad",
	// 				"addressRegion": "Gujrat",
	// 				"postalCode": "380009",
	// 				"addressCountry": "India"
	// 			},
	// 			"contactPoint": {
	// 				"@type": "ContactPoint",
	// 				"telephone": "+91 79 48917579"
	// 			},
	// 			"geo": {
	// 				"@type": "GeoCoordinates",
	// 				"latitude": 23.0335,
	// 				"longitude": 72.5557,
	// 			},
	// 			"aggregateRating": {
	// 				"@type": "AggregateRating",
	// 				"ratingValue": "4.9",
	// 				"bestRating": "5",
	// 				"ratingCount": "17"
	// 			}
	// 		},
	// 	}),
	// },
	{
		url: ['/services/web-development/python-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Web Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Python Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development/python-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/web-development/laravel-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Web Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Laravel Development',
					item: 'https://www.thirdrocktechkno.com/services/web-development/laravel-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "How much does a mobile application cost?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The cost of developing a mobile application varies from one project to another on the basis of the application platform and the kind of features and functionalities you need in the application. Contact us with your project requirements to get a cost estimation for your project."
					}
				}, {
					"@type": "Question",
					"name": "How long does it take to develop a custom mobile application?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Again, mobile app development for different kinds of applications requires different timeframes. If you need a project to be delivered in a certain period of time, we can make it possible by employing the necessary resources in the development process."
					}
				}, {
					"@type": "Question",
					"name": "Can I hire dedicated developers for mobile app development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, you can hire dedicated developers for your project. We offer multiple payment models to enable you to choose an approach that suits you the best."
					}
				}, {
					"@type": "Question",
					"name": "Does Third Rock Techkno provide maintenance and support for mobile app development after the project is completed?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Certainly. We commit free support for a certain period once the project is live. After the free support period ends, you can get complete maintenance and support for your project at very nominal rates."
					}
				}, {
					"@type": "Question",
					"name": "Why choose Third Rock Techkno for mobile app development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "With 5+ years of experience and 50+ satisfied customers, our versatile development teams and consultants with sharp business acumen are experts in building efficient native, hybrid and cross platform mobile apps with maximum ROI."
					}
				},]
			}
		}),
	},
	{
		url: ['/services/mobile-development/ionic-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Ionic Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/ionic-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/react-native-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'React Native Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/react-native-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/android-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Android Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/android-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/ios-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Ios Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/ios-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/flutter-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Flutter Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/flutter-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/mobile-development/ar-development/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Mobile Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'AR Development',
					item: 'https://www.thirdrocktechkno.com/services/mobile-development/ar-development/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/devops/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Devops',
					item: 'https://www.thirdrocktechkno.com/services/devops'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/devops/devops-consulting/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Devops',
					item: 'https://www.thirdrocktechkno.com/services/devops/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Devops Consulting',
					item: 'https://www.thirdrocktechkno.com/services/devops/devops-consulting/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/devops/ci-cd-implementation-and-management/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Devops',
					item: 'https://www.thirdrocktechkno.com/services/devops/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'CI CD Implementation and Management',
					item: 'https://www.thirdrocktechkno.com/services/devops/ci-cd-implementation-and-management/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/devops/infrastructure-management-and-monitoring/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Devops',
					item: 'https://www.thirdrocktechkno.com/services/devops/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Infrastructure Management and Monitoring',
					item: 'https://www.thirdrocktechkno.com/services/devops/infrastructure-management-and-monitoring/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/devops/infrastructure-as-a-code/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Devops',
					item: 'https://www.thirdrocktechkno.com/services/devops/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Infrastructure as a Code',
					item: 'https://www.thirdrocktechkno.com/services/devops/infrastructure-as-a-code/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/test-automation/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Test Automation',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/test-automation/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/api-testing/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Api Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/api-testing/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/microservices-testing/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Microservices Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/microservices-testing/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/performance-testing/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Performance Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/performance-testing/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/services/software-testing/security-testing/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Services',
					item: 'https://www.thirdrocktechkno.com/services'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Security Testing',
					item: 'https://www.thirdrocktechkno.com/services/software-testing/security-testing/'
				}],
			},
			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/hire-us/hire-voice-developers/hire-alexa-skill-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Voice Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-voice-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Alexa Voice Assistant',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-voice-developers/hire-alexa-skill-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Alexa Skills for my business?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Alexa skills are being increasingly used in mobile app development because of how useful it is in keeping the users engaged. It provides your business with an opportunity to connect with your customers in a more personalized way."
						}
					}, {
						"@type": "Question",
						"name": "What are some best Alexa Skills in 2020?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Alexa Skills has been used by both startups and enterprise apps globally. Examples of top Alexa Skills in 2020 include FindMyPhone, CureFit, Valossa Movie Finder etc.."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire Alexa Skills developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring Alexa Skills developers from Third Rock Techkno High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Alexa Skills developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Alexa Skills developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Alexa Skills developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					},
					{
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Alexa Skills developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Alexa Skills developers depends on the scale and required functionalities of your application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Alexa Skills project requirements and get free cost estimates."
						}
					},
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-voice-developers/hire-google-actions-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Voice Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-voice-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Google Actions Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-voice-developers/hire-google-actions-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Google Actions for my business?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Google Home Actions are being increasingly used in mobile app development because of how useful they are in keeping the users engaged. It provides your business with an opportunity to connect with your customers in a more personalized way."
						}
					}, {
						"@type": "Question",
						"name": "What are some best Google Assistant Actions in 2020?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Google Assistant Actions are increasingly being used by both startups and enterprise apps globally. Examples of top Google Assistant Commands in 2020 include unlocking your phone hands-free, recommendations for nearby places, setting reminders etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire Google Actions developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring Google Actions developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Google Actions developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Google Actions developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Google Actions developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Google Actions developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Google Actions developers depends on the scale and required functionalities of your application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Google Actions project requirements and get free cost estimates."
						}
					},

				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-web-developer/hire-angular-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Angular JS Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-angular-developer/'
				}],
			}
		}),
		"FAQ":
		{
			"@context": "https://schema.org",
			"@type": "FAQPage",
			"mainEntity": [
				{
					"@type": "Question",
					"name": "What are the advantages of using AngularJS for my project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Angular application development facilitates the maximum benefits out of agile methodology. In addition to being a cost-effective platform, Angular is considered a highly stable and credible platform for app development due to its huge community and support from Google."
					}
				}, {
					"@type": "Question",
					"name": "Will I get a dedicated Angular developer for my project in my local timezone?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes. You can hire dedicated developers for Angular and AngularJS from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
					}
				}, {
					"@type": "Question",
					"name": "How much does it cost to hire a dedicated AngularJS developer?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The cost of hiring dedicated angular developers depends on the scale and required functionalities of your Angular application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your AngularJS project requirements and get free cost estimates."
					}
				}, {
					"@type": "Question",
					"name": "What is the difference between Angular and AngularJS?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "AngularJS was the first version of Angular released in 2010 by Google. However, with changing market demands, Google rewrote the code and launched an improved version called Angular 2 in 2016. Since then, Angular has released a new version every six months and all these versions are referred to as Angular. However, AngularJS is still relevant and popular because of its features and advantages."
					}
				}, {
					"@type": "Question",
					"name": "What are some best web apps developed with AngularJS?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "AngularJS for enterprise applications is extremely beneficial. That is why, some of the largest apps of all time including Gmail App, Upwork, PayPal, The Guardian etc. have used AngularJS for web application development."
					}
				}, {
					"@type": "Question",
					"name": "Why should I hire Angular developers from Third Rock Techkno?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Advantages of hiring dedicated Angular developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Angular developers"
					}
				},

			]
		}
	},
	{
		url: ['/hire-us/hire-web-developer/hire-node-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Node JS Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-node-developer/'
				}],
			}
		}),
		"FAQ":
		{
			"@context": "https://schema.org",
			"@type": "FAQPage",
			"mainEntity": [
				{
					"@type": "Question",
					"name": "What are the advantages of using NodeJS for my project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "NodeJS facilitates development of building both the front end and the back end of a web application. So you don’t have to hire different developers for both the aspects. It is incredibly beneficial in terms of writing easily maintainable code and providing fast loading of heavy apps."
					}
				}, {
					"@type": "Question",
					"name": "What are some best web apps developed with NodeJS?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "NodeJS is extremely beneficial for reducing app load time and boosting performance. That is why, some of the most efficient apps of all time including Netflix, Uber, LinkedIn etc. have used NodeJS for web application development."
					}
				}, {
					"@type": "Question",
					"name": "Why should I hire NodeJS developers from Third Rock Techkno?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Advantages of hiring NodeJS developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated NodeJS developers"
					}
				}, {
					"@type": "Question",
					"name": "Will I get a dedicated NodeJS developer for my project in my local timezone?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes. You can hire dedicated NodeJS developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
					}
				}, {
					"@type": "Question",
					"name": "How much does it cost to hire a dedicated NodeJS developer?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The cost of hiring dedicated NodeJS developers depends on the scale and required functionalities of your NodeJS application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your NodeJS project requirements and get free cost estimates."
					}
				}
			]
		}
	},
	{
		url: ['/hire-us/hire-web-developer/hire-reactjs-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire React JS Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-reactjs-developer/'
				}],
				"FAQ":
				{
					"@context": "https://schema.org",
					"@type": "FAQPage",
					"mainEntity": [
						{
							"@type": "Question",
							"name": "What are the advantages of using ReactJS for my project?",
							"acceptedAnswer": {
								"@type": "Answer",
								"text": "ReactJS is incredible for building fast, interactive and scalable web applications. With an exhaustive library of UI components, it helps develop engaging and dynamic interfaces in a very efficient manner."
							}
						}, {
							"@type": "Question",
							"name": "What are some best web apps developed with ReactJS?",
							"acceptedAnswer": {
								"@type": "Answer",
								"text": "ReactJS for enterprise applications is extremely beneficial. That is why, some of the largest apps of all time including Facebook, Dropbox, Netflix etc. have used ReactJS for web application development."
							}
						}, {
							"@type": "Question",
							"name": "Why should I hire ReactJS developers from Third Rock Techkno?",
							"acceptedAnswer": {
								"@type": "Answer",
								"text": "Advantages of hiring ReactJS developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated ReactJS developers"
							}
						}, {
							"@type": "Question",
							"name": "Will I get a dedicated Reactjs developer for my project in my local timezone?",
							"acceptedAnswer": {
								"@type": "Answer",
								"text": "Yes. You can hire dedicated ReactJS developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
							}
						}, {
							"@type": "Question",
							"name": "How much does it cost to hire a dedicated ReactJS developer?",
							"acceptedAnswer": {
								"@type": "Answer",
								"text": "The cost of hiring dedicated ReactJS developers depends on the scale and required functionalities of your ReactJS application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your ReactJS project requirements and get free cost estimates."
							}
						}
					]
				}
			}
		}),
	},
	{
		url: ['/hire-us/hire-web-developer/hire-vuejs-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Vue JS Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-vuejs-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using VueJS for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "VueJS is incredible for building fast, interactive and scalable web applications. As a highly flexible and adaptable framework, it helps develop engaging and dynamic interfaces in a very efficient manner."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with VueJS?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "VueJS is extremely beneficial for building simple, clutter free apps. That is why, some of the most efficient apps of all time including Grammarly, Alibaba, GitLab etc. have used VueJS for web application development."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire VueJS developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring VueJS developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated VueJS developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated VueJS developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated VueJS developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated VueJS developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated VueJS developers depends on the scale and required functionalities of your VueJS application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your VueJS project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-web-developer/hire-python-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Python Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-python-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Python  for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Python  is incredible for building fast, interactive and scalable web applications. As a highly flexible and adaptable framework, it helps develop engaging and dynamic interfaces in a very efficient manner."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with Python ?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Python  is extremely beneficial for building simple, clutter free apps. That is why, some of the most efficient apps of all time including Grammarly, Alibaba, GitLab etc. have used Python  for web application development."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire Python  developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring Python  developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Python  developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Python  developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Python  developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Python  developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Python  developers depends on the scale and required functionalities of your Python  application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Python  project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-web-developer/hire-laravel-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Web Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Laravel Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-web-developer/hire-laravel-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Laravel   for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Laravel   is incredible for building fast, interactive and scalable web applications. As a highly flexible and adaptable framework, it helps develop engaging and dynamic interfaces in a very efficient manner."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with Laravel  ?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Laravel   is extremely beneficial for building simple, clutter free apps. That is why, some of the most efficient apps of all time including Grammarly, Alibaba, GitLab etc. have used Laravel   for web application development."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire Laravel   developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring Laravel   developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Laravel   developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Laravel   developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Laravel   developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Laravel   developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Laravel   developers depends on the scale and required functionalities of your Laravel   application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Laravel   project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-ionic-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Ionic Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/hire-ionic-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Ionic  for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Ionic  is incredible for building fast, interactive and scalable web applications. As a highly flexible and adaptable framework, it helps develop engaging and dynamic interfaces in a very efficient manner."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with Ionic ?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Ionic  is extremely beneficial for building simple, clutter free apps. That is why, some of the most efficient apps of all time including Grammarly, Alibaba, GitLab etc. have used Ionic  for web application development."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire Ionic  developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring Ionic  developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated Ionic  developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Ionic  developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Ionic  developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Ionic  developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Ionic  developers depends on the scale and required functionalities of your Ionic  application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Ionic  project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-react-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire React Native Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-react-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using React Native for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "React Native is a cross platform app development framework that enables you to build applications for both Android and iOS using a single codebase. It is known for providing optimal performance to native apps with features like hot loading and modular architecture."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with React Native?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "React Native has been used by both startups and enterprise apps globally. Examples of top React Native apps include Facebook Ads, Skype, UberEats etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire React Native developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring React Native developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated React Native developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated React Native developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated React Native developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated React Native developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated React Native developers depends on the scale and required functionalities of your React Native application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your React Native project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-android-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Android Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/hire-android-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Android for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Android is the most popular technology used for building mobile applications quickly and easily. It is highly cost-effective and has a mature ecosystem of numerous third-party tools for easy customization."
						}
					}, {
						"@type": "Question",
						"name": "What are some best apps developed with Android?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Android has been used by both startups and enterprise apps globally. Examples of top Android apps include 1Weather, Google Drive, Microsoft SwiftKey etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire React Native developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring React Native developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated React Native developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Android developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated Android developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Android developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Android developers depends on the scale and required functionalities of your application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your Android project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-ios-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire iOS Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/hire-ios-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using IOS for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "IOS is the most popular technology used for building mobile applications quickly and easily. It is highly cost-effective and has a mature ecosystem of numerous third-party tools for easy customization."
						}
					}, {
						"@type": "Question",
						"name": "What are some best apps developed with IOS?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "IOS has been used by both startups and enterprise apps globally. Examples of top IOS apps include 1Weather, Google Drive, Microsoft SwiftKey etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire React Native developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring React Native developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated React Native developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated IOS developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated IOS developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated IOS developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated IOS developers depends on the scale and required functionalities of your application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your IOS project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-flutter-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire Flutter Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/hire-flutter-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using Flutter for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Flutter is an excellent choice for developing cross-platform apps for web, Android and iOS, all using a single codebase. It has its own rendering engine Skia which provides full control over every pixel on screen and gives lighting fast speed of 60 FPS. Also, it has a plugin to establish communication to your OS with its Dart language and native code."
						}
					}, {
						"@type": "Question",
						"name": "What are some best web apps developed with Flutter?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Flutter is an incredibly powerful platform to build dynamic yet simple apps. That is why it has been a major source for developing some popular apps like Google Ads, Hamilton, Reflectly, Birch Finance, Hookie, Postmuse, Coach Yourself, etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire React Native developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring React Native developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated React Native developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated Flutter developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes, Third Rock Techkno provides dedicated services to hire Flutter developers who will be at your full disposal for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated Flutter developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated Flutter app developers depends on the scale and required functionalities of your Flutter application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your VueJS project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/hire-us/hire-mobile-app-developers/hire-ar-developer/'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Hire Us',
					item: 'https://www.thirdrocktechkno.com/hire-us'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Hire Mobile Developers',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/'
				}, {
					'@type': 'ListItem',
					position: 4,
					name: 'Hire AR Developer',
					item: 'https://www.thirdrocktechkno.com/hire-us/hire-mobile-app-developers/hire-ar-developer/'
				}],
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [
					{
						"@type": "Question",
						"name": "What are the advantages of using AR for my project?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "AR is being increasingly used in mobile app development because of how useful it is in keeping the users engaged. AR provides your business with an opportunity to connect with your customers in a more personalized way."
						}
					}, {
						"@type": "Question",
						"name": "What are some best apps developed with AR?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "AR has been used by both startups and enterprise apps globally. Examples of top AR apps include Snapchat, Ask Mercedes, IKEA Place etc."
						}
					}, {
						"@type": "Question",
						"name": "Why should I hire React Native developers from Third Rock Techkno?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Advantages of hiring React Native developers from Third Rock Techkno include: High ROI with minimum cost Excellent quality standards Agile and lean development approach Active post development support Direct communication with dedicated React Native developers"
						}
					}, {
						"@type": "Question",
						"name": "Will I get a dedicated AR developer for my project in my local timezone?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "Yes. You can hire dedicated AR developers from Third Rock Techkno who will work for at least 8 hours a day in your local time zone."
						}
					}, {
						"@type": "Question",
						"name": "How much does it cost to hire a dedicated AR app developer?",
						"acceptedAnswer": {
							"@type": "Answer",
							"text": "The cost of hiring dedicated AR developers depends on the scale and required functionalities of your application. We offer flexible engagement models that will maximize your ROI. Contact Us to discuss your AR project requirements and get free cost estimates."
						}
					}
				]
			}
		}),
	},
	{
		url: ['/services/voice-assistant-applications-development'],
		value: JSON.stringify({
			'@context': 'https://schema.org/',
			'@type': 'BreadcrumbList',
			itemListElement: [{
				'@type': 'ListItem',
				position: 1,
				name: 'Home',
				item: 'https://www.thirdrocktechkno.com'
			}, {
				'@type': 'ListItem',
				position: 2,
				name: 'Services',
				item: 'https://www.thirdrocktechkno.com/services'
			}, {
				'@type': 'ListItem',
				position: 3,
				name: 'Voice Development',
				item: 'https://www.thirdrocktechkno.com/services/voice-assistant-applications-development'
			}],
		}),
	},
	{
		url: ['/solutions'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
			
			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				},],
			},

			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
		}),
	},
	{
		url: ['/solutions/software-development-services-for-startups'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "From MVPs to Full-Fledged Software Solutions. Our dedicated team offers custom software development services for startups in various industries. ",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'Software Development Services for Startups',
					item: 'https://www.thirdrocktechkno.com/solutions/software-development-services-for-startups'
				},
			],
			},

			"FAQPage":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "What are the benefits of outsourcing software development for my startup to Third Rock Techkno?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Outsourcing software development allows you to access our pool of experienced and skilled professionals having 5-20 years of startup development experience, who can deliver quality work at a lower cost than in-house developers. It also allows you to focus on your core competencies while leaving the software development work to experts."
					}
				}, {
					"@type": "Question",
					"name": "How can I ensure the security of my startup's sensitive information?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "To ensure the security of your startup's sensitive information, we have established security protocols in place. You can also sign a non-disclosure agreement (NDA) with us to protect your intellectual property."
					}
				}, {
					"@type": "Question",
					"name": "How to hire software developers for startup from Third Rock Techkno?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We offer a flexible solution where we provide talented developers on hire for short-term or long-term projects. Our pool of skilled developers are experienced in various programming languages and frameworks, and are ready to work with your team to help you achieve your goals. With our on-hire service, startups can quickly and easily access top software development talent without the hassle of a lengthy recruitment process."
					}
				}, {
					"@type": "Question",
					"name": "What is your development process?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Our development process typically involves the following steps: requirements gathering, prototyping, development, testing, deployment, and maintenance. We also provide regular updates and progress reports throughout the project. Learn more about our project management process here."
					}
				},
				{
					"@type": "Question",
					"name": "What is your pricing model?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Our pricing model is based on the scope and complexity of the project. We provide a detailed estimate after the initial consultation, and we work with our clients to ensure that the project is within their budget."
					}
				},
				{
					"@type": "Question",
					"name": "How long does it take to complete a project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The timeline for completing a project depends on various factors, such as the scope, complexity, and client requirements. We provide a detailed project plan with estimated timelines during the initial FREE consultation."
					}
				},
				{
					"@type": "Question",
					"name": "How do you handle changes or revisions during the project?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We welcome feedback and revisions from our clients throughout the project. We provide regular updates and progress reports, and we work closely with our clients to ensure that the project meets their expectations."
					}
				},
			]
			}
		}),
	},
	{
		url: ['/solutions/mvp-development-services'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a reliable company specializing in MVP development services, providing exceptional solutions to help bring your product idea to life.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'MVP Development Service',
					item: 'https://www.thirdrocktechkno.com/solutions/mvp-development-services'
				},
			],
			},

			"FAQPage":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "How long does it take to develop an MVP?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We understand that as a startup or small business owner, time is of the essence. That's why we offer flexible MVP development services that are tailored to your unique needs. The time required to develop an MVP depends on several factors, such as the scope of work, the complexity of the product, and the resources available. But don't worry, our team of experts is here to help you every step of the way. We provide a detailed project plan and timeline that takes into account your specific requirements, allowing you to plan and budget accordingly."
					}
				}, {
					"@type": "Question",
					"name": "How much does it cost to develop an MVP?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "At Third Rock Techkno, we know that you need to carefully plan and budget for your product development. That's why we provide a detailed project plan and cost estimate for MVP development, based on the specific requirements of each client. We take into account factors such as the scope of work, the complexity of the product, and the resources required, to provide a transparent and accurate cost estimate."
					}
				}, {
					"@type": "Question",
					"name": "What is your experience in MVP development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Our team has extensive experience in developing MVPs for startups and small businesses across a range of industries. We leverage our technical expertise and domain knowledge to develop high-quality solutions that meet our clients' needs."
					}
				}, {
					"@type": "Question",
					"name": "How do you ensure the quality of the final product?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We have a rigorous quality assurance process in place that includes testing and debugging at every stage of the development process. We also provide ongoing support and maintenance services to ensure the product's continued success."
					}
				},
				{
					"@type": "Question",
					"name": "How do I get started with MVP development services?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "You can contact us to schedule a consultation and discuss your MVP development needs. Our team will work with you to develop a project plan and timeline based on your specific requirements"
					}
				},
			]
			}
		}),
	},
	{
		url: ['/solutions/healthcare-software-development'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a reliable company specializing in MVP development services, providing exceptional solutions to help bring your product idea to life.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				},
				{
					'@type': 'ListItem',
					position: 3,
					name: 'Healthcare Software Development',
					item: 'https://www.thirdrocktechkno.com/solutions/healthcare-software-development'
				},
			],
			},

			"FAQPage":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "How do you ensure the security and privacy of patient data?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We take data security and privacy very seriously and adhere to industry-standard security protocols, including HIPAA compliance. Our team also undergoes regular security training to ensure that they are up-to-date with the latest security best practices."
					}
				}, {
					"@type": "Question",
					"name": "Can you integrate with existing healthcare systems?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, we can integrate with existing healthcare systems and ensure that the new software we develop is seamlessly integrated with your existing technology stack."
					}
				}, {
					"@type": "Question",
					"name": "What is your development process like?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Our development process is agile and iterative, which means that we work closely with our clients to understand their needs and requirements and deliver software in stages, with regular feedback and testing."
					}
				}, {
					"@type": "Question",
					"name": "How long does it take to develop healthcare software?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The development timeline can vary depending on the complexity of the project, the number of features, and the resources available. We work with our clients to provide an estimated timeline and keep them updated throughout the development process."
					}
				},
				{
					"@type": "Question",
					"name": "What is the cost of healthcare software development?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "The average cost of healthcare software development ranges from $15,000 to $150,000. But the cost of healthcare software development can vary depending on the scope of the project, the number of features, and the resources required. We provide our clients with a transparent pricing structure and work with them to find a solution that fits their budget."
					}
				},
				{
					"@type": "Question",
					"name": "What if I have a specific healthcare software requirement that is not listed on your website?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We are always happy to discuss specific requirements and develop custom solutions that meet your unique needs. Contact us to learn more about how we can help you."
					}
				},
			]
			}
		}),
	},
	{
		url: ['/solutions/fitness-app-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Fitness App Development',
					item: 'https://www.thirdrocktechkno.com/solutions/fitness-app-development/'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
				  "@type": "Question",
				  "name": "What is fitness app development software?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Fitness app development software refers to the technology and tools used to create, design, and build mobile applications specifically for the fitness industry. It enables businesses to create customized fitness apps with features like workout tracking, nutrition planning, class scheduling, and more."
				  }
				},{
				  "@type": "Question",
				  "name": "Why do I need a fitness app for my business?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "A fitness app can greatly benefit your business by offering convenience, engagement, and personalization to your customers. It allows you to provide workout routines, track progress, send push notifications, offer in-app purchases, and enhance overall user experience. It can also help you expand your reach, attract new customers, and boost customer loyalty."
				  }
				},{
				  "@type": "Question",
				  "name": "How long does it take to develop a fitness app?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "The development timeline depends on various factors such as the complexity of the app, desired features, and design requirements. Typically, a fitness app can take anywhere from a few weeks to several months to develop. We work closely with our clients to provide realistic timelines and ensure timely delivery."
				  }
				},{
				  "@type": "Question",
				  "name": "Will my fitness app be compatible with both iOS and Android devices?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, we specialize in developing cross-platform fitness apps that are compatible with both iOS and Android devices. By leveraging technologies like React Native or Flutter, we ensure your app reaches a wider audience and provides a consistent experience across different platforms."
				  }
				},{
				  "@type": "Question",
				  "name": "Can you integrate third-party fitness tracking devices with the app?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Absolutely! We have expertise in integrating various fitness tracking devices and wearables with fitness apps. This includes compatibility with devices like smartwatches, heart rate monitors, pedometers, and more, allowing users to seamlessly track their workouts and progress within the app."
				  }
				}]
			  }
		}),
	},
	{
		url: ['/solutions/fintech-software-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Fintech Software Development',
					item: 'https://www.thirdrocktechkno.com/solutions/fintech-software-development'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
					"@context": "https://schema.org",
					"@type": "FAQPage",
					"mainEntity": [
					  {
						"@type": "Question",
						"name": "Why should I choose a specialized fintech software development company?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "Choosing a specialized fintech software development company ensures that you work with professionals who have in-depth knowledge and expertise in both finance and technology. We understand the unique challenges and requirements of the fintech industry, enabling them to deliver tailored solutions that meet your specific needs."
						}
					  },
					  {
						"@type": "Question",
						"name": "How do you ensure the security of fintech software applications?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "We prioritize security throughout the development process. Our team follows industry best practices and implements robust security measures, such as encryption, multi-factor authentication, and compliance with relevant regulations (e.g., GDPR, PCI-DSS). We conduct thorough security testing and offer ongoing maintenance and support to address any vulnerabilities."
						}
					  },
					  {
						"@type": "Question",
						"name": "How long does it take to develop a fintech software application?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "The development timeline depends on several factors, including the complexity of the project, the desired features and functionalities, and the scope of work. After discussing your requirements, we can provide you with an estimated timeline during the initial consultation phase."
						}
					  },
					  {
						"@type": "Question",
						"name": "What is the cost of fintech software development?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "The cost of fintech software development varies depending on factors such as project complexity, development hours required, and additional features. We provide customized pricing based on your specific requirements, and our team will work closely with you to ensure that the project fits within your budget."
						}
					  },
					  {
						"@type": "Question",
						"name": "What is the cost of fintech software development?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "As a ballpark figure, the cost of developing a fintech application typically starts from $50,000 and can go up to several hundred thousand dollars, depending on factors such as project complexity, development hours required, and additional features. We provide customized pricing based on your specific requirements, and our team will work closely with you to ensure that the project fits within your budget."
						}
					  },
					  {
						"@type": "Question",
						"name": "How can I get started with my fintech software development project?",
						"acceptedAnswer": {
						  "@type": "Answer",
						  "text": "To get started, simply reach out to us through our contact form, phone, or email. Our team will be happy to schedule an initial consultation to discuss your project requirements, goals, and timeline. From there, we can provide a detailed proposal and begin the exciting journey of developing your fintech software solution."
						}
					  }
					]
			}
		}),
	},
	{
		url: ['/solutions/entertainment-app-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno offers comprehensive software services for developing OTT and entertainment apps globally.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Entertainment App Development Company',
					item: 'https://www.thirdrocktechkno.com//solutions/entertainment-app-development'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "What types of Media and Entertainment apps can you develop?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "We specialize in developing a wide range of Media and Entertainment apps, including music streaming apps, video streaming platforms, social media apps for artists, event ticketing apps, podcasting apps, gaming apps, and more."
					}
				}, {
					"@type": "Question",
					"name": "What is the average time frame to develop a standard OTT application??",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "On average, developing a standard OTT application can take anywhere from three to six months. This timeframe includes various stages of app development, such as requirement gathering, UI/UX design, front-end and back-end development, integration with third-party APIs, testing, and deployment.It's important to note that this estimate is a general guideline and can vary based on the specific requirements and complexities of the OTT application. Projects with more advanced features, customizations, or additional integrations may require a longer development timeline."
					}
				}, {
					"@type": "Question",
					"name": "Can you integrate third-party APIs into the app?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Absolutely! We have expertise in integrating various third-party APIs to enhance the functionality of Media and Entertainment apps. This includes APIs for music streaming, video hosting, social media integration, payment gateways, and more. We ensure seamless integration to provide a smooth user experience."
					}
				}, {
					"@type": "Question",
					"name": "Do you provide ongoing app maintenance and support?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, we offer comprehensive app maintenance and support services. Once the app is developed and launched, we provide continuous monitoring, bug fixing, and updates to ensure the app's optimal performance. We also offer support for scalability, new feature additions, and future platform updates."
					}
				},]
			}
		}),
	},
	{
		url: ['/solutions/education-software-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Education Software Solutions Development',
					item: 'https://www.thirdrocktechkno.com/solutions/education-software-development'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},

			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
					"@type": "Question",
					"name": "What are custom education application development services?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Custom education application development services refer to the process of creating tailored mobile or web applications specifically designed for educational institutions, such as schools, colleges, universities, and other organizations in the education sector. These applications are built to cater to the unique needs and requirements of the institution, providing features and functionalities that enhance the learning experience for students and streamline administrative processes for educators."
					}
				}, {
					"@type": "Question",
					"name": "Can you provide examples of features that can be included in custom education applications?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Some common features that can be included in custom education applications are student information management systems, attendance tracking, grade and assessment management, online course materials and resources, virtual classrooms for remote learning, interactive quizzes and assessments, progress tracking and reporting, communication tools (such as messaging and notifications), and integration with learning management systems (LMS) or other educational platforms."
					}
				}, {
					"@type": "Question",
					"name": "Can the custom education application be scaled and updated in the future?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, a well-designed custom education application should be scalable and flexible to accommodate future growth and changes in the institution's needs. This includes the ability to add new features, incorporate updates, and integrate with other systems or third-party services as required. When discussing your requirements with the development team, make sure to address scalability and future-proofing considerations to ensure the longevity of the application."
					}
				},
				{
					"@type": "Question",
					"name": "Can you provide references or examples of previous custom education application development projects you have developed?",
					"acceptedAnswer": {
						"@type": "Answer",
						"text": "Yes, as a reputable education software development company we can provide case studies, or examples of our previous work which we have done which you can check out in our portfolio section. This will give you insights into our expertise, capabilities, and the quality of our deliverables."
					}
				},
			]
			}
		}),
	},
	{
		url: ['/solutions/custom-crm-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Custom CRM Development',
					item: 'https://www.thirdrocktechkno.com/solutions/custom-crm-development/'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
				  "@type": "Question",
				  "name": "What is CRM development?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "CRM development refers to the process of creating customized software solutions specifically tailored to manage customer relationships, streamline sales processes, and enhance overall business operations. It involves designing and implementing features and functionalities that align with a company's unique requirements and objectives."
				  }
				},{
				  "@type": "Question",
				  "name": "Why should I opt for custom CRM development instead of using off-the-shelf CRM software?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Custom CRM development offers several advantages over off-the-shelf solutions. It allows you to have full control over the features, integrations, and user experience of your CRM system. Customization ensures that the CRM solution perfectly aligns with your specific business processes and requirements, enhancing efficiency and enabling better customer relationship management."
				  }
				},{
				  "@type": "Question",
				  "name": "How can a customized CRM system benefit my business?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "A customized CRM system can provide numerous benefits for your business. It enables streamlined sales processes, enhanced lead management, improved customer engagement, better data management and analysis, personalized marketing and communication, increased productivity, and better overall business performance. With a tailored CRM system, you can optimize your operations, drive growth, and achieve a competitive edge."
				  }
				},{
				  "@type": "Question",
				  "name": "Can a custom CRM system be integrated with other software and tools?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, a custom CRM system can be integrated with other software and tools commonly used in your business operations. Integration capabilities enable seamless data exchange between the CRM system and various platforms such as email marketing tools, customer support systems, analytics platforms, and more. This integration enhances efficiency, eliminates data silos, and provides a holistic view of your customer interactions and business processes."
				  }
				},{
				  "@type": "Question",
				  "name": "What kind of support and maintenance is provided after CRM development?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Third Rock Techkno offers ongoing support and maintenance for the custom CRM solution. This includes addressing any technical issues, applying software updates and patches, providing user support, and ensuring the smooth functioning of the CRM system."
				  }
				},{
				  "@type": "Question",
				  "name": "Can I migrate data from my existing CRM system to a custom CRM solution?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Yes, it is possible to migrate data from your existing CRM system to a custom CRM solution. We can assist you in data migration, ensuring a smooth transition from your current CRM system to the new custom solution. This process involves mapping and transferring data while ensuring data integrity and minimizing disruptions to your business operations."
				  }
				},{
				  "@type": "Question",
				  "name": "How much does it cost to develop custom CRM software?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "While we cannot provide an exact cost without understanding your specific needs, our custom CRM projects typically range from $20,000 to $100,000 or more. It's important to note that ongoing costs may include hosting, maintenance, support, and future enhancements. To get a precise estimate tailored to your requirements, we encourage you to reach out to our expert team for a detailed consultation. We'll assess your needs and provide you with a customized cost estimate aligned with your business objectives."
				  }
				}]
			  }
		}),
	},
	{
		url: ['/solutions/enterprise-app-development-services'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"BreadcrumbList":
			{
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Solutions',
					item: 'https://www.thirdrocktechkno.com/solutions'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Custom CRM Development',
					item: 'https://www.thirdrocktechkno.com/solutions/enterprise-app-development-services/'
				}],
			},

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},
			"LocalBusiness": {
				'@context': 'https://schema.org',
				'@type': 'LocalBusiness',
				name: 'Third Rock Techkno',
				image: 'https://www.thirdrocktechkno.com/images/logo.png',
				'@id': 'https://www.thirdrocktechkno.com/',
				url: 'https://www.thirdrocktechkno.com/',
				priceRange: '$25 - $49',
				"address": {
					"@type": "PostalAddress",
					"streetAddress": "103-104, Sarita Complex, Jain Temple Lane, Opp. Municipal Market, C.G. Road, Navrangpura",
					"addressLocality": "Ahmedabad",
					"addressRegion": "Gujrat",
					"postalCode": "380009",
					"addressCountry": "India"
				},
				"contactPoint": {
					"@type": "ContactPoint",
					"telephone": "+91 79 48917579"
				},
				"geo": {
					"@type": "GeoCoordinates",
					"latitude": 23.0335,
					"longitude": 72.5557,
				},
				"aggregateRating": {
					"@type": "AggregateRating",
					"ratingValue": "4.9",
					"bestRating": "5",
					"ratingCount": "17"
				}
			},
			"FAQ":
			{
				"@context": "https://schema.org",
				"@type": "FAQPage",
				"mainEntity": [{
				  "@type": "Question",
				  "name": "How long does it take to develop an enterprise app?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "The development time depends on various factors, such as the complexity of the app, required features, and integration requirements. We provide a detailed timeline during the discovery phase."
				  }
				},{
				  "@type": "Question",
				  "name": "Can you integrate the app with our existing systems?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "Absolutely! We specialize in seamless integrations and can connect your app with your existing systems, ensuring a smooth transition and data synchronization."
				  }
				},{
				  "@type": "Question",
				  "name": "Do you provide ongoing support and maintenance?",
				  "acceptedAnswer": {
					"@type": "Answer",
					"text": "We offer comprehensive post-development support and maintenance services to keep your app up-to-date, secure, and performing optimally. Our team of testers and support developers is always on the line to assist with any technical support."
				  }
				}]
			  }
		}),
	},
	{
		url: ['/ebooks'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/the-ultimate-startup-guide-to-product-development'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'The ltimate startup guide to product development',
					item: 'https://www.thirdrocktechkno.com/ebooks/the-ultimate-startup-guide-to-product-development'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/the-ultimate-startup-guide-to-product-development/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/the-ultimate-startup-guide-to-product-development/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/the-ultimate-startup-guide-to-product-development/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-development-outsourcing-all-the-essentials-in-one-place'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software Development Outsourcing all the Essentials in one place',
					item: 'https://www.thirdrocktechkno.com/ebooks/software-development-outsourcing-all-the-essentials-in-one-place'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-development-outsourcing-all-the-essentials-in-one-place/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-requirements-specification-document-template'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Software requirements specification document template',
					item: 'https://www.thirdrocktechkno.com/ebooks/software-requirements-specification-document-template'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/software-requirements-specification-document-template/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-requirements-specification-document-template/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/software-requirements-specification-document-template/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/offshore-developer-rates'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Offshore developer rates',
					item: 'https://www.thirdrocktechkno.com/ebooks/offshore-developer-rates'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/offshore-developer-rates/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/offshore-developer-rates/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/offshore-developer-rates/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/how-to-build-saas-product'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'How to build saas product',
					item: 'https://www.thirdrocktechkno.com/ebooks/how-to-build-saas-product'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/how-to-build-saas-product/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/how-to-build-saas-product/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/how-to-build-saas-product/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/enterprise-web-application-guide'],
		value: JSON.stringify({
			"@type": "Website",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",

			"Organization":
			{
				"@context": "http://www.schema.org",
				"@type": "Organization",
				"name": "Third Rock Techkno",
				"url": "https://www.thirdrocktechkno.com",
				"logo": "https://www.thirdrocktechkno.com/images/logo.png",
				"image": "https://www.thirdrocktechkno.com/images/logo.png",
				"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
				"sameAs": [
					"https://www.facebook.com/ThirdRockTechkno",
					"https://twitter.com/thirdrocktechno",
					"https://www.linkedin.com/company/third-rock-techkno",
				],
			},

			"BreadcrumbList": {
				'@context': 'https://schema.org/',
				'@type': 'BreadcrumbList',
				itemListElement: [{
					'@type': 'ListItem',
					position: 1,
					name: 'Home',
					item: 'https://www.thirdrocktechkno.com/'
				}, {
					'@type': 'ListItem',
					position: 2,
					name: 'Ebooks',
					item: 'https://www.thirdrocktechkno.com/ebooks'
				}, {
					'@type': 'ListItem',
					position: 3,
					name: 'Enterprise web application guide',
					item: 'https://www.thirdrocktechkno.com/ebooks/enterprise-web-application-guide'
				}],
			},
		}),
	},
	{
		url: ['/ebooks/enterprise-web-application-guide/page-01'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/enterprise-web-application-guide/page-02'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/ebooks/enterprise-web-application-guide/page-03'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
	{
		url: ['/our-clients'],
		value: JSON.stringify({
			"@context": "http://www.schema.org",
			"@type": "Organization",
			"name": "Third Rock Techkno",
			"url": "https://www.thirdrocktechkno.com",
			"logo": "https://www.thirdrocktechkno.com/images/logo.png",
			"image": "https://www.thirdrocktechkno.com/images/logo.png",
			"description": "Third Rock Techkno is a leading web and mobile app development company in India & USA. Our services include angular development, Node js development services, react native app development and more.",
			"sameAs": [
				"https://www.facebook.com/ThirdRockTechkno",
				"https://twitter.com/thirdrocktechno",
				"https://www.linkedin.com/company/third-rock-techkno",
			],
		}),
	},
];
